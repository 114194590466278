let conteoAcade = 0;

function animationAcade(tit, parte1, parte2, timeImg, timeInfo) {
    const tit1 = document.querySelector(tit);
    const part1 = document.querySelector(parte1);
    const part2 = document.querySelector(parte2);

    tit1.style.animation = `appear-c 1s ease .5s 1 normal forwards`;
    part1.style.animation = `appear-c ${timeImg}s ease .5s 1 normal forwards`;
    part2.style.animation = `move-c ${timeInfo}s ease .5s 1 normal forwards`;
}

window.addEventListener('scroll', function() {
    const y = window.scrollY;
    const anchoPantalla = document.body.clientWidth;
 
    if(conteoAcade < 1) {
        if(anchoPantalla > 800 && anchoPantalla < 1419) {
            if(y >= 2753 && y <= 4253) {
                animationAcade('.academico-responsive .titulo', '.academico-responsive .grid .img-estudiante', '.academico-responsive .grid .info', 2, 3);
                conteoAcade++;
             } 
        } else if(anchoPantalla > 480 && anchoPantalla < 801){
            if(y >= 3887 && y <= 5587) {
                animationAcade('.academico-responsive .titulo', '.academico-responsive .grid .img-estudiante', '.academico-responsive .grid .info', 3, 2);
                conteoAcade++;
            }
        } else if(anchoPantalla < 481){
            if(y >= 2700 && y <= 4300) {
                animationAcade('.academico-responsive .titulo', '.academico-responsive .grid .img-estudiante', '.academico-responsive .grid .info', 3, 2);
                conteoAcade++;
            }
        }
    }
  });